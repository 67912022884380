import Api from './Api';

export function createCoupon(params) {
  const exec = `
    mutation createCoupon(
      $code: String!
    ) {
      createCoupon(
        code: $code
      ) {
        id
        code
        kind
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function getCoupons() {
  const exec = `
    query getCoupons {
      coupons {
        id
        code
        kind
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec)
  };
}
