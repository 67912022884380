import Api from './Api';

export function getOrders() {
  const exec = `
    query affiliateOrders {
      affiliateOrders {
        id
        total
        comission
        approved
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec)
  };
}
