import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { getOrders } from '../api/Order';
import User from './UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    minWidth: "200px",
    maxWidth: "500px",
    padding: theme.spacing(2),
  },
  order: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,

    "&:last-child": {
      borderBottom: "none",
    }
  },
  empty: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  }
}));

export default function OrdersPage() {
  const classes = useStyles();
  const { user } = React.useContext(User);
  const [orders, setOrders] = React.useState();
  const [pendingOrders, setPendingOrders] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [pendingTotal, setPendingTotal] = React.useState(0);
  const [pendingComission, setPendingComission] = React.useState(0);
  const [approvedComission, setApprovedComission] = React.useState(0);

  const fetchOrders = () => {
    const { fetcher } = getOrders();

    fetcher.then((res) => {
      const approvedOrders = res.affiliateOrders.filter((order) => order.approved);
      const pendingOrders = res.affiliateOrders.filter((order) => !order.approved);
      const total = approvedOrders.reduce((acc, order) => acc + order.total, 0);
      const pendingTotal = pendingOrders.reduce((acc, order) => acc + order.total, 0);
      const pendingComission = pendingOrders.reduce((acc, order) => acc + order.comission, 0);
      const approvedComission = approvedOrders.reduce((acc, order) => acc + order.comission, 0);

      setOrders(approvedOrders);
      setPendingOrders(pendingOrders);
      setTotal(total);
      setPendingTotal(pendingTotal);
      setPendingComission(pendingComission);
      setApprovedComission(approvedComission);
    });
  };

  const formatCurrency = (value) => {
    return (value / 100).toFixed(2).replace('.', ',');
  };

  const orderItem = (order) => (
    <div key={order.id} className={classes.order}>
      <Typography variant="body1" gutterBottom>Total: R$ {formatCurrency(order.total)}</Typography>
      <Typography variant="body1" gutterBottom>Comissão: R$ {formatCurrency(order.comission)}</Typography>
      <Typography variant="body1" gutterBottom component="div">
        {order.approved ? (
          <Chip label="Aprovado" color="primary" />
        ) : (
          <Chip label="Pendente" color="default" />
        )}
      </Typography>
    </div>
  );

  React.useEffect(() => {
    fetchOrders();
  }, []);

  if (!user || !orders || !pendingOrders) return <></>;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          Total de vendas
        </Typography>

        <Typography variant="body1">
          <b>Aprovadas:</b> R$ {formatCurrency(total)} (R$ {formatCurrency(approvedComission)} de comissão)
          <br />
          <b>Pendentes:</b> R$ {formatCurrency(pendingTotal)} (R$ {formatCurrency(pendingComission)} de comissão)
        </Typography>

        <br />
        <Divider />
        <br />

        <Typography variant="h5">
          Vendas aprovadas
        </Typography>

        { orders && orders.length === 0 && (
          <Typography variant="body1" gutterBottom className={classes.empty}>
            Nenhuma venda aprovada ainda.
          </Typography>
        )}

        <br />

        { orders && orders.map((order) => orderItem(order)) }

        <br />

        <Typography variant="h5" gutterBottom>
          Vendas pendentes
        </Typography>

        { pendingOrders && pendingOrders.length === 0 && (
          <Typography variant="body1" gutterBottom className={classes.empty}>
            Nenhuma venda pendente ainda.
          </Typography>
        )}

        { pendingOrders && pendingOrders.map((order) => orderItem(order)) }
      </Paper>
    </div>
  );
}
