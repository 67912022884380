import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import { createCoupon, getCoupons } from '../api/Coupon';
import User from './UserContext';
import Alert from '../util/Alerts';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    minWidth: "200px",
    maxWidth: "500px",
    padding: theme.spacing(2),
  },
  coupon: {
    minWidth: "200px",
    maxWidth: "500px",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  }
}));

export default function CouponsPage() {
  const classes = useStyles();
  const { user } = React.useContext(User);
  const [notice, setNotice] = React.useState();
  const [alert, setAlert] = React.useState();
  const [code, setCode] = React.useState('');
  const [coupons, setCoupons] = React.useState();

  const fetchCoupons = () => {
    const { fetcher } = getCoupons();

    fetcher.then((res) => {
      setCoupons(res.coupons);
    });
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { fetcher } = createCoupon({ code });

    fetcher.then((res) => {
      setCode('');
      setAlert(null);
      setNotice('Cupom criado com sucesso');
      fetchCoupons();
    }).catch((err) => {
      setNotice(null);
      setAlert(err.response.errors[0].attributes?.code.join(', '));
    });
  };

  const copyToClipboard = (code) => {
    setNotice(null);
    navigator.clipboard.writeText(code);
    setNotice('Cupom copiado');
  };

  React.useEffect(() => {
    fetchCoupons();
  }, []);

  if (!user || !coupons) return <></>;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          Cupons
        </Typography>

        <Typography variant="body1" gutterBottom>
          Crie cupons de cashback em dobro para seus clientes
        </Typography>

        <Divider />
        <br />

        <Alert message={notice} onClose={() => { setNotice(null) }} />
        <Alert error message={alert} onClose={() => { setAlert(null) }} />

        <form onSubmit={handleSubmit}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Código do cupom</InputLabel>
            <OutlinedInput onChange={handleCodeChange} labelWidth={130} value={code} />

            <br />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<Icon>add</Icon>}
            >
              Criar cupom
            </Button>
          </FormControl>
        </form>
      </Paper>

      <h2>Cupons criados</h2>

      { coupons && coupons.map((coupon) => (
        <Paper key={coupon.id} className={classes.coupon}>
          <Typography variant="h4" gutterBottom>{coupon.code}</Typography>
          <Button onClick={() => copyToClipboard(coupon.code)} variant="contained" color="primary" startIcon={<Icon>file_copy</Icon>}>Copiar</Button>
        </Paper>
      )) }
    </div>
  );
}
