import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default function CustomAlert({ message, error, onClose }) {
  const [open, setOpen] = React.useState();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);

    if (typeof(onClose) === 'function') {
      onClose();
    }
  };

  React.useEffect(() => {
    setOpen(!!message);
  }, [message]);

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={error ? 'error' : 'success'}>
        {message}
      </Alert>
    </Snackbar>
  );
}
